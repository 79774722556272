import { Timestamp, collection, endAt, getDocs, limit, orderBy, query, startAt } from 'firebase/firestore'
import { Geopoint, distanceBetween, geohashQueryBounds } from 'geofire-common'
import React, { useEffect, useState } from 'react'
import { firestore } from '../firebase/Firebase'
import noResults from './search.png'
import Modal from './Modal'

type PropsType = {
    queriedLocationData: { name: string, lat: number, lng: number } | undefined
}

type CSCType = {
  name: string,
  createdAt: Timestamp,
  logoUrl: string,
  mainUrl?: string,
  g: {
      lat: number,
      lng: number,
      hash: string,
      city: string,
      region: string,
  }
}

function DisplayResults({ queriedLocationData } : PropsType) {
    const [nearbyResults, setNearbyResults] = useState<(CSCType & {distance: number})[] | null>([])
    const [randomResults, setRandomResults] = useState<(CSCType & {distance: number})[] | null>(null)
    const [showModal, setShowModal] = useState(false)
    const selectedRadiusInKM = "20";


   useEffect(() => {
    if(queriedLocationData) {
        const center : Geopoint = [queriedLocationData.lat, queriedLocationData.lng]
        const radiusInM = parseFloat(selectedRadiusInKM) * 1000
        const bounds = geohashQueryBounds(center, radiusInM)
        const promises = [];

        for (const b of bounds) {
            const q = query(collection(firestore, "cscs"), orderBy('g.hash'), startAt(b[0]), endAt(b[1])) //hier mit or query limit einbauen, wenn viele Clubs kommen
            promises.push(getDocs(q));
        }

        Promise.all(promises).then((snapshots) => {
            const matchingDocs : (CSCType & {distance: number})[] = [];
          
            for (const snap of snapshots) {
              for (const doc of snap.docs) {
                const lat = doc.get('g.lat');
                const lng = doc.get('g.lng');
          
                //filter false positives
                const distanceInKm = distanceBetween([lat, lng], center);
                const distanceInM = distanceInKm * 1000;
                if (distanceInM <= radiusInM) {
                  matchingDocs.push({distance: distanceBetween([lat, lng], center),  ...doc.data() as CSCType});
                }
              }
            }
          
            return matchingDocs;
          }).then((matchingDocs) => {
            if(matchingDocs.length > 0) {
                setNearbyResults(matchingDocs.sort((a,b) => a.distance - b.distance))
            } else {
                setNearbyResults(null)
            }
          });

    }
   }, [queriedLocationData, selectedRadiusInKM])

   useEffect(() => {
     if(true) {
        const q = query(collection(firestore, "cscs"), limit(10))
        const center : Geopoint = [queriedLocationData?.lat || 0, queriedLocationData?.lng || 0]
        const results : any = [];
        getDocs(q).then(docs => {
            docs.docs.forEach(csc => {
                results.push({
                  ...csc.data(),
                  distance: queriedLocationData ? distanceBetween([csc.data().g.lat, csc.data().g.lng], center) : null
                })
            })
            setRandomResults(results.sort((a :any,b:any) => a.distance - b.distance))
        })
     }
   }, [queriedLocationData]) //kann optimiert werden
   

   const classOption = ['bg-emerald-300', 'bg-amber-300', 'bg-indigo-200', 'bg-red-300']

   const renderClubResult = (r: CSCType & {distance?: number}) => (
        <a href={r.mainUrl || ''} target='_blank' className='flex flex-col mt-6 cursor-pointer' rel="noreferrer">
        <div className={'relative z-0 flex justify-center items-center aspect-[4/3] rounded-xl w-full ' + classOption[Math.floor(Math.random() * classOption.length)]}>
            <div className='absolute aspect-square bg-slate-900 rounded-lg w-7/12 translate-x-4 md:translate-x-2 translate-y-4 md:translate-y-2 z-0'></div>
            <div className=' aspect-square bg-white rounded-lg w-7/12 z-10 flex justify-center items-center'>
              <img src={r.logoUrl} alt="logo vom csc" className='w-10/12' />
            </div>
        </div>
        <p className=" text-gray-900 mt-2 font-semibold text-lg">{r.name}</p>
        <p className=" -mt-1 text-gray-400 text">{r.g.city}, {r.g.region}</p>
        {typeof r.distance == "number" ? <p className='-mt-1 text-gray-400 text-sm'>{r.distance.toLocaleString(undefined, { maximumFractionDigits: 2 })} km </p> : null}
        </a>
   )

    
  return (
    <div className='mx-auto container px-4 pb-8'>
      { queriedLocationData &&
      <div className="text-center ">
      <div onClick={() => setShowModal(true)} className="mt-8 px-3 py-2 bg-indigo-700 items-center text-indigo-100 leading-none rounded-full flex lg:inline-flex cursor-pointer" role="alert">
        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Neu</span>
        <span className=" text-sm md:text-base font-semibold mr-2 text-left flex-auto">Benachrichtige mich, sobald ein neuer Club eingetragen wird</span>
        <svg className="fill-current opacity-75 h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
      </div>
    </div>
      }
      {nearbyResults && nearbyResults.length > 0 &&
      <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
      { nearbyResults.map((r : any) => renderClubResult(r))}
      </div>
      }
      {nearbyResults == null &&
      <>
      <div className='mt-16'>
        <img src={noResults} className='w-8/12 mx-auto max-w-[10rem]' alt="no-results" />
        <p className='text-center text-gray-500 text-lg font-semibold'>Leider keine Ergebnisse</p>
        <p className='text-center text-gray-500 mb-4 px-16 mx-auto'>In dieser Umgebung sind leider noch keine Clubs eingetragen. </p>
      </div>
      </>
      }
      { randomResults && randomResults.length > 0 &&
        <>
        {queriedLocationData && <hr className='mt-20' />}
        <h1 className='text-3xl font-black pt-8'>Empfohlene Clubs in Deutschland</h1>
        <div className="text-center ">
        <div onClick={() => setShowModal(true)} className="mt-8 px-3 py-2 bg-indigo-700 items-center text-indigo-100 leading-none rounded-full flex lg:inline-flex cursor-pointer" role="alert">
          <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">Neu</span>
          <span className=" text-sm md:text-base font-semibold mr-2 text-left flex-auto">Benachrichtige mich, sobald ein neuer Club eingetragen wird</span>
          <svg className="fill-current opacity-75 h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"/></svg>
        </div>
      </div>
        <div className='grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4'>
            {randomResults.map((r:any) => renderClubResult(r))}
        </div>
        </>
      }
      {showModal && <Modal setShowModal={setShowModal} queriedLocationData={queriedLocationData}/>}
    </div>
  )
}

export default DisplayResults