import { addDoc, collection, doc, endAt, getDocs, orderBy, query, setDoc, startAt } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { analytics, firestore } from './firebase/Firebase';
import AutoComplete from './autocomplete/AutoComplete';
import Addcsc from './addcsc/AddCsc';
import { Geopoint, distanceBetween, geohashForLocation, geohashQueryBounds } from 'geofire-common';
import DisplayResults from './displayresults/DisplayResults';
import LogoRose from './logo-rose.svg'
import LogoWhite from './logo-white.svg'
import AdminAddCscAddCsc from './AdminAddCsc/AdminAddCsc';
import AdminAddCsc from './AdminAddCsc/AdminAddCsc';
import { logEvent } from 'firebase/analytics';

function App() {
  const can_add = false
  const [queriedLocationData, setQueriedLocationData] = useState< { name: string, lat: number, lng: number }|undefined>()
  const [showModal, setShowModal] = useState(false)
  const [showA, setShowA] = useState(false)
  
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  useEffect(() => {
    logEvent(analytics, 'screen_view', {
      firebase_screen: "test", 
      firebase_screen_class: "test"
    });
  }, [])
  

  return (
    <div className="App">
      <div className='flex flex-col md:flex-row justify-start md:justify-center items-center border-b-2 h-40 md:h-24 bg-white fixed top-0 left-0 right-0 z-10'>
        <div className='px-4 w-full h-16 bg-slate-800 md:hidden text-center text-white flex justify-between items-center'>
          <a href="https://www.weedlink.de" target='_blank' rel="noreferrer">
            <img src={LogoWhite} alt="logo" className='w-8 h-8'/>
          </a>
          <div className='flex gap-4'>
            <a href="https://www.weedlink.de" target='_blank' className='hover:underline text-gray-200' rel="noreferrer">
              Zur Startseite
            </a>
            <p className='hover:underline text-gray-200' onClick={() => setShowModal(true)}>
              CSC eintragen
            </p>
          </div>
        </div>
        <div className='container flex-1 flex flex-col md:flex-row justify-start md:justify-between items-center'>
          <a href="https://www.weedlink.de" target='_blank' className="hidden md:block  w-15 h-15 pl-4 justify-self-start flex-1" rel="noreferrer">
            <img src={LogoRose} className='w-10 h-10' alt="test" />
          </a>
          <div className='px-4 w-full max-w-lg my-auto'>
            <div className="w-full py-4 p-3 rounded-full flex items-center bg-white shadow-xl shadow-gray-200/80 border" onClick={() => setIsSearchOpen(!isSearchOpen)}>
                      <svg className="w-6 h-6 ml-1" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                      {queriedLocationData ?
                        <p className='flex-1 font-semibold ml-3'>
                          {queriedLocationData.name}
                        </p>
                          :
                        <p className='flex-1 ml-3 text-gray-500'>
                          Stadt wählen
                        </p>
                      }
            </div>
          </div>
          <div className="hidden md:flex justify-end flex-1" onClick={() => setShowModal(true)}>
            <p className=' text-slate-800 font-semibold hover:text-slate-900 hover:underline cursor-pointer'>CSC eintragen</p>
          </div>
          {can_add && 
          <>
            <div className=' text-red-600 ml-2 cursor-pointer bg-red-100 p-2 rounded-full' onClick={() => setShowA(true)}>Add</div>
            {showA && <AdminAddCsc setShowModal={setShowA}/> }
          </>
          }
        </div>
      </div>
      {isSearchOpen && <AutoComplete queriedLocationData={queriedLocationData} setQueriedLocationData={setQueriedLocationData} setIsSearchOpen={setIsSearchOpen} />}
      <div className='mt-40 md:mt-24'>

      </div>
      {queriedLocationData && 
        <div className='mx-auto container px-4 mb-4 pt-2'>
            <h1 className="mt-4 text-3xl font-black">Clubs in {queriedLocationData?.name}</h1>
        </div>
      }
      
      <DisplayResults queriedLocationData={queriedLocationData} />
      {showModal && <Addcsc setShowModal={setShowModal} />}
    </div>
  );
}

export default App;
