import React, { useEffect, useState } from 'react'
import geofire from 'geofire-common'
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { firestore } from '../firebase/Firebase';

type PropsType = {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
}

function AddCsc({ setShowModal } : PropsType) {
  const [mailInput, setMailInput] = useState("")
  const [nameInput, setNameInput] = useState("")
  const [phoneInput, setPhoneInput] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = (e : any) => {
    e.preventDefault();
    addDoc(collection(firestore, 'entryRequests'), {
        mail: mailInput,
        phone: phoneInput,
        cscname: nameInput,
        createdAt: serverTimestamp(),
    })
    .then(() => setSubmitted(true))
}

useEffect(() => {
  document.body.style.overflow = 'hidden'

  return () => {
    document.body.style.overflow = 'auto'
  }
}, [])



  return (
      <div id="authentication-modal" tabIndex={-1} aria-hidden="true" className="fixed h-screen inset-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 max-h-full flex justify-center items-center bg-gray-900/50">
        <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow ">
               { !submitted ?
                <>
                <button onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-hide="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
                <div className="px-6 py-6 lg:px-8">
                    <h3 className="mb-4 text-xl font-medium text-gray-900 ">Meinen CSC eintragen</h3>
                    <p className='text-gray-700 font-semibold mb-2'>
                      Falls wir deinen Club noch nicht eingetragen haben, fülle einfach das Formular aus und dein Club wird innerhalb von 24h eingepflegt.
                    </p>
                    <hr className='mb-2' />
                    
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label  className="block mb-2 text-sm font-medium text-gray-900 ">Name des CSC</label>
                                <input value={nameInput} onChange={e => setNameInput(e.target.value)}  type="text" name="text" id="email" className="text-[16px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:bodarkrder-blue-500 block w-full p-2.5 :bg-gray-600 " placeholder="CSC Berlin" required />
                            </div>
                            <div>
                                <label  className="block mb-2 text-sm font-medium text-gray-900 ">Kontakt E-Mail</label>
                                <input value={mailInput} onChange={e => setMailInput(e.target.value)}  type="email" name="email" id="email" className="text-[16px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:bodarkrder-blue-500 block w-full p-2.5 :bg-gray-600 " placeholder="name@csc.com" required />
                            </div>
                            <div>
                                <label  className="block mb-2 text-sm font-medium text-gray-900 ">Kontakt - Telefonnummer</label>
                                <input value={phoneInput} onChange={e => setPhoneInput(e.target.value)}  type="tel" name="tel" id="tel" className="text-[16px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:bodarkrder-blue-500 block w-full p-2.5 :bg-gray-600 " placeholder="0170 1234567" required />
                            </div>
                            <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Trage meinen Club ein</button>
                        </form>


                        <p className='text-sm text-gray-400 mt-4'>
                          Deine Kontakt-Daten werden ausschließlich für die Kontaktaufnahme bei Problemen  & Änderungen gespeichert. Sie werden verschlüsselt auf deutschen Servern gespeichert und niemals an Dritte herausgegeben.
                        </p>
                </div>
                </>
                :
                <div className='px-6 py-16 text-center'>

                <button onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-hide="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
                  <p className='text-xl font-semibold text-emerald-700'>Erfolg!</p>
                  <p className='mt-2 text-gray-500'>
                    Dein Antrag wurde erfolgreich abgeschickt, wir melden uns in wenigen Stunden bei dir und fragen nach weiteren Details
                  </p>
                </div>
               }
            </div>
        </div>
      </div>
  )
}

export default AddCsc