import React, { useState, useEffect } from "react";
import { FiMapPin } from 'react-icons/fi'
import { Transition } from '@headlessui/react'
import { firestore } from "../firebase/Firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";

type PropsType = {
    queriedLocationData: { name: string, lat: number, lng: number } | undefined
    setQueriedLocationData: React.Dispatch<React.SetStateAction<{ name: string, lat: number, lng: number } | undefined>>
    setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const AutoComplete = ({ queriedLocationData, setQueriedLocationData, setIsSearchOpen} : PropsType) => {
    //AIzaSyDzV6gbfw6w9oDsnygHXKcgyI-ihf10Qn4
    const [text, setText] = useState("")
    const [results, setResults] = useState<google.maps.places.AutocompletePrediction[]>([])

    const autocomplete = new window.google.maps.places.AutocompleteService();
    const placeService = new window.google.maps.places.PlacesService(document.createElement("div"));


   useEffect(() => {
    if(text) {
        autocomplete.getPlacePredictions({input: text, types: ['(cities)'], componentRestrictions: {country: 'DE'}}, async (pred) => {
            const qresults = pred || [];
            setResults(qresults);
        });
     }
   }, [text])

   const handleLocationClick = (p: google.maps.places.AutocompletePrediction) => {
    placeService.getDetails({ placeId: p.place_id}, r => {
        setText("")
        setResults([])
        if(window.location.hostname !== "suche.weedlink.de") { //im dev nicht protokollieren
            setQueriedLocationData({
                name: p.description,
                lat: r?.geometry?.location?.lat() ?? 0,
                lng: r?.geometry?.location?.lng() ?? 0,
            })    
            setIsSearchOpen(false)
            return
        }
        addDoc(collection(firestore, 'queries'), {
            queriedLocationData: {
                name: p.description,
                lat: r?.geometry?.location?.lat() ?? 0,
                lng: r?.geometry?.location?.lng() ?? 0,
            },
            createdAt: serverTimestamp(),
        })
        .then(() => {
            setQueriedLocationData({
                name: p.description,
                lat: r?.geometry?.location?.lat() ?? 0,
                lng: r?.geometry?.location?.lng() ?? 0,
            })    
            setIsSearchOpen(false)
        })
        
        
    })
   }

    return (
       <div className="px-4 absolute inset-0 bg-white flex flex-col z-20
       md:max-w-lg md:left-1/2 md:-translate-x-1/2 md:top-28 md:shadow-2xl md:shadow-gray-900 md:rounded-2xl md:h-auto md:bottom-auto
       "
       >
        <div className="flex justify-start mt-8">
            <div className="rounded-full bg-gray-100 border-gray-400 w-10 h-10 border flex items-center justify-center" onClick={() => setIsSearchOpen(false)}>
            <svg className="w-5 h-5" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.85355 3.14645C7.04882 3.34171 7.04882 3.65829 6.85355 3.85355L3.70711 7H12.5C12.7761 7 13 7.22386 13 7.5C13 7.77614 12.7761 8 12.5 8H3.70711L6.85355 11.1464C7.04882 11.3417 7.04882 11.6583 6.85355 11.8536C6.65829 12.0488 6.34171 12.0488 6.14645 11.8536L2.14645 7.85355C1.95118 7.65829 1.95118 7.34171 2.14645 7.14645L6.14645 3.14645C6.34171 2.95118 6.65829 2.95118 6.85355 3.14645Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
            </div>
        </div>
         <div className=" pt-8 h-32 bg-white">
            <div className="w-full px-3 flex rounded-lg bg-gray-100 justify-between items-center mb-4 relative">
                <svg className="w-5 h-5" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
                <input autoFocus className=" text-[16px] py-4 bg-transparent rounded-full px-4 outline-none  flex-1"
                type="text" value={text} onChange={e => setText(e.target.value)} placeholder="Suche nach einer Stadt">
                </input>
            </div>
        </div>
        <div className="bg-white flex-1">
            {results.map(r => (
                <div className="flex items-center gap-4 text-gray-800 w-full mb-5 cursor-pointer" onClick={() => handleLocationClick(r)}>
                    <div className="w-14 h-14 bg-stone-200 rounded-xl flex justify-center items-center">
                    <FiMapPin className="text-2xl" />
                    </div>
                    <p>{r.description}</p>
                </div>
            ))}
            </div>
       </div>
    );
};

export default AutoComplete;
