import { addDoc, collection, serverTimestamp } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { firestore } from '../firebase/Firebase'


type PropsType = {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    queriedLocationData: { name: string, lat: number, lng: number } | undefined
}

function Modal({setShowModal, queriedLocationData} : PropsType) {
    const [selectedMethod, setSelectedMethod] = useState(1)
    const [mailInput, setMailInput] = useState("")
    const [phoneInput, setPhoneInput] = useState("")

    const handleSubmit = (e : any) => {
        e.preventDefault();
        addDoc(collection(firestore, 'mailingListPlatform'), {
            mail: mailInput,
            phone: phoneInput,
            queriedLocationData: queriedLocationData || null,
            createdAt: serverTimestamp(),
        })
        .then(() => setShowModal(false))
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden'
      
        return () => {
          document.body.style.overflow = 'auto'
        }
      }, [])

  return (  
    <div id="authentication-modal" tabIndex={-1} aria-hidden="true" className="fixed h-screen inset-0 z-50  w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex justify-center items-center bg-gray-900/50">
    <div className="relative w-full max-w-md max-h-full">
        <div className="relative bg-white rounded-lg shadow ">
            <button onClick={() => setShowModal(false)} type="button" className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center " data-modal-hide="authentication-modal">
                <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <div className="px-6 py-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 ">Benachrichtige mich bei neuen Clubs!</h3>
                <p className='text-gray-600 mb-8'>
                    Wir benachrichtigen dich nur, sobald neue Clubs <span className=' text-emerald-600 font-semibold'>in deiner Region</span> eingetragen wurden und wenn es wichtige Neuigkeiten zur Legalisierung in Deutschland gibt!

                </p>
                <ul className="text-sm font-medium text-center text-gray-500 divide-x divide-gray-200 rounded-lg shadow flex mb-4">
                    <li className="w-full" onClick={() => setSelectedMethod(1)}>
                        <div className={"inline-block w-full p-4 text-gray-900 rounded-l-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none " + (selectedMethod === 1  ? 'bg-gray-200' : "") }>
                            Per Mail
                        </div>
                    </li>
                    <li className="w-full" onClick={() => setSelectedMethod(2)}>
                        <div className={"inline-block w-full p-4 text-gray-900 rounded-r-lg focus:ring-4 focus:ring-blue-300 active focus:outline-none " + (selectedMethod === 2  ? 'bg-gray-200' : "" )} >
                            Per WhatsApp
                        </div>
                    </li>
                </ul>
                {selectedMethod === 1 &&
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label  className="block mb-2 text-sm font-medium text-gray-900 ">Deine E-Mail</label>
                            <input value={mailInput} onChange={e => setMailInput(e.target.value)} type="email" name="email" id="email" className="text-[16px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:bodarkrder-blue-500 block w-full p-2.5 :bg-gray-600 " placeholder="name@mail.de" required />
                        </div>
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Benachrichtige mich</button>
                    </form>
                }

                {selectedMethod === 2 &&
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label  className="block mb-2 text-sm font-medium text-gray-900 ">Deine WhatsApp - Nummer</label>
                            <input value={phoneInput} onChange={e => setPhoneInput(e.target.value)} type="tel" name="email" id="email" className="text-[16px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:bodarkrder-blue-500 block w-full p-2.5 :bg-gray-600 " placeholder="0170 1234567" required />
                        </div>
                        <button type="submit" className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">Benachrichtige mich</button>
                    </form>
                }
              
            </div>
        </div>
    </div>
</div> 
  )
}

export default Modal